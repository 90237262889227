.extra-marker {
  background: url("../img/markers_default.png") no-repeat 0 0;
  width: 35px;
  height: 46px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  text-align: center
}

.extra-marker-shadow {
  background: url("../img/markers_shadow.png") no-repeat 0 0;
  width: 36px;
  height: 16px
}

@media (min--moz-device-pixel-ratio:1.5),
(-webkit-min-device-pixel-ratio:1.5),
(min-device-pixel-ratio:1.5),
(min-resolution:1.5dppx) {
  .extra-marker {
    background-image: url("../img/markers_default@2x.png");
    background-size: 540px 184px
  }

  .extra-marker-shadow {
    background-image: url("../img/markers_shadow@2x.png");
    background-size: 35px 16px
  }
}

.extra-marker .svg-inline--fa,
.extra-marker i {
  color: #fff;
  margin-top: 7px;
  display: inline-block;
  font-size: 14px
}

.extra-marker .svg-inline--fa {
  margin-top: 10px;
  background: none
}

.extra-marker .svg-inline--fa,
.extra-marker i.fa,
.extra-marker i.fab,
.extra-marker i.fas,
.extra-marker i.far,
.extra-marker i.fal {
  margin-top: 10px
}

.extra-marker .svg-inline--fa.fa-2x,
.extra-marker i.fa.fa-2x,
.extra-marker i.fab.fa-2x,
.extra-marker i.fas.fa-2x,
.extra-marker i.far.fa-2x,
.extra-marker i.fal.fa-2x {
  font-size: 16px;
  margin-top: 9px
}

.extra-marker .svg-inline--fa.fa-3x,
.extra-marker i.fa.fa-3x,
.extra-marker i.fab.fa-3x,
.extra-marker i.fas.fa-3x,
.extra-marker i.far.fa-3x,
.extra-marker i.fal.fa-3x {
  font-size: 18px;
  margin-top: 9px
}

.extra-marker .svg-inline--fa.fa-4x,
.extra-marker i.fa.fa-4x,
.extra-marker i.fab.fa-4x,
.extra-marker i.fas.fa-4x,
.extra-marker i.far.fa-4x,
.extra-marker i.fal.fa-4x {
  font-size: 20px;
  margin-top: 8px
}

.extra-marker .svg-inline--fa.fa-5x,
.extra-marker i.fa.fa-5x,
.extra-marker i.fab.fa-5x,
.extra-marker i.fas.fa-5x,
.extra-marker i.far.fa-5x,
.extra-marker i.fal.fa-5x {
  font-size: 24px;
  margin-top: 6px
}

.extra-marker .fa-number:before {
  content: attr(number)
}

.extra-marker i.glyphicon {
  margin-top: 10px
}

.extra-marker i.icon {
  margin-right: 0;
  opacity: 1
}

.extra-marker-circle-red {
  background-position: 0 0
}

.extra-marker-circle-orange-dark {
  background-position: -36px 0
}

.extra-marker-circle-orange {
  background-position: -72px 0
}

.extra-marker-circle-yellow {
  background-position: -108px 0
}

.extra-marker-circle-blue-dark {
  background-position: -144px 0
}

.extra-marker-circle-blue {
  background-position: -180px 0
}

.extra-marker-circle-cyan {
  background-position: -216px 0
}

.extra-marker-circle-purple {
  background-position: -252px 0
}

.extra-marker-circle-violet {
  background-position: -288px 0
}

.extra-marker-circle-pink {
  background-position: -324px 0
}

.extra-marker-circle-green-dark {
  background-position: -360px 0
}

.extra-marker-circle-green {
  background-position: -396px 0
}

.extra-marker-circle-green-light {
  background-position: -432px 0
}

.extra-marker-circle-black {
  background-position: -468px 0
}

.extra-marker-circle-white {
  background-position: -504px 0
}

.extra-marker-square-red {
  background-position: 0 -46px
}

.extra-marker-square-orange-dark {
  background-position: -36px -46px
}

.extra-marker-square-orange {
  background-position: -72px -46px
}

.extra-marker-square-yellow {
  background-position: -108px -46px
}

.extra-marker-square-blue-dark {
  background-position: -144px -46px
}

.extra-marker-square-blue {
  background-position: -180px -46px
}

.extra-marker-square-cyan {
  background-position: -216px -46px
}

.extra-marker-square-purple {
  background-position: -252px -46px
}

.extra-marker-square-violet {
  background-position: -288px -46px
}

.extra-marker-square-pink {
  background-position: -324px -46px
}

.extra-marker-square-green-dark {
  background-position: -360px -46px
}

.extra-marker-square-green {
  background-position: -396px -46px
}

.extra-marker-square-green-light {
  background-position: -432px -46px
}

.extra-marker-square-black {
  background-position: -468px -46px
}

.extra-marker-square-white {
  background-position: -504px -46px
}

.extra-marker-star-red {
  background-position: 0 -92px
}

.extra-marker-star-orange-dark {
  background-position: -36px -92px
}

.extra-marker-star-orange {
  background-position: -72px -92px
}

.extra-marker-star-yellow {
  background-position: -108px -92px
}

.extra-marker-star-blue-dark {
  background-position: -144px -92px
}

.extra-marker-star-blue {
  background-position: -180px -92px
}

.extra-marker-star-cyan {
  background-position: -216px -92px
}

.extra-marker-star-purple {
  background-position: -252px -92px
}

.extra-marker-star-violet {
  background-position: -288px -92px
}

.extra-marker-star-pink {
  background-position: -324px -92px
}

.extra-marker-star-green-dark {
  background-position: -360px -92px
}

.extra-marker-star-green {
  background-position: -396px -92px
}

.extra-marker-star-green-light {
  background-position: -432px -92px
}

.extra-marker-star-black {
  background-position: -468px -92px
}

.extra-marker-star-white {
  background-position: -504px -92px
}

.extra-marker-penta-red {
  background-position: 0 -138px
}

.extra-marker-penta-orange-dark {
  background-position: -36px -138px
}

.extra-marker-penta-orange {
  background-position: -72px -138px
}

.extra-marker-penta-yellow {
  background-position: -108px -138px
}

.extra-marker-penta-blue-dark {
  background-position: -144px -138px
}

.extra-marker-penta-blue {
  background-position: -180px -138px
}

.extra-marker-penta-cyan {
  background-position: -216px -138px
}

.extra-marker-penta-purple {
  background-position: -252px -138px
}

.extra-marker-penta-violet {
  background-position: -288px -138px
}

.extra-marker-penta-pink {
  background-position: -324px -138px
}

.extra-marker-penta-green-dark {
  background-position: -360px -138px
}

.extra-marker-penta-green {
  background-position: -396px -138px
}

.extra-marker-penta-green-light {
  background-position: -432px -138px
}

.extra-marker-penta-black {
  background-position: -468px -138px
}

.extra-marker-penta-white {
  background-position: -504px -138px
}